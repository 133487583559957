/* eslint-disable @typescript-eslint/no-explicit-any */
import { Exceptions_ErrorModel } from '@monorepo-types/dc';

import type { ApiResult } from './types';

/**
 * This class wraps the errors from DC
 * DC will expose its errors in the body via the 'errors' prop
 * That errors prop is an array of errors shaped like:
 * instanceId: string; // guid for the individual occurance - useful for backtracking in dc logs nothing more
 * code?: string; // a unique identifer prefixed with a namespace seperated with a pipe (|)
 * details?: string; // debugging information - not available in production
 * type?: string; // The human readable error type (for instance DataNotFoundExeception) - useful in case client code wants to address certain issues with a specific UX
 * innerErrors?: Array<Exceptions_ErrorModel>; // The potential underlying errors to this error - mostly to implementation detailed for FE to use
 *
 * In the near future this model will be extended with front end friendly error messages
 */

export class ApiError extends Error {
  public readonly url: URL;
  public readonly status: number;
  public readonly statusText: string;
  public readonly body: any;

  public readonly hasErrors: boolean;
  public readonly errors?: Exceptions_ErrorModel[];
  public readonly firstError?: Exceptions_ErrorModel;

  constructor(response: ApiResult, message: string) {
    super(message);

    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.body;

    try {
      this.errors = this.body.errors as Exceptions_ErrorModel[];
      this.firstError = this.errors?.[0];
      this.hasErrors = true;
    } catch (err) {
      this.hasErrors = false;
    }
  }
}
